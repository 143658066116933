/**
 * Case Conversion utilities
 * Source: https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript
 *
 * Since we're passing objects to and from Rails, it's useful to convert to and
 * from the conventional cases for Javascript and Rails. These utilities handle
 * that task for us.
 */

export const isArray = (a) => Array.isArray(a);

export const isObject = (o) =>
  o === Object(o) &&
  !isArray(o) &&
  typeof o !== 'function' &&
  !(o instanceof Date);

// TODO: Add logic to camelCase for numbers also. eg: address_2 => address2
export const toCamel = (s) =>
  s.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace('-', '').replace('_', ''),
  );

export const toSnake = (s) =>
  s.replace(/\.?([ A-Z])/g, (x, y) => `_${y.toLowerCase()}`);

export const keysToCamel = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => keysToCamel(i));
  }

  return o;
};

export const keysToSnake = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toSnake(k)] = keysToSnake(o[k]);
    });

    return n;
  }

  if (isArray(o)) {
    return o.map((i) => keysToSnake(i));
  }

  return o;
};

/*
 * Return the string in sentense case with converting the snake keys.
 * eg. String - "start_time must be present" will be converted to
 *  "Start time must be present"
 *
 * @param {String || null} s
 */
export const toSentenceCase = (s) => {
  if (!s) return '';

  const string = s.replace(/([-_])/g, ' ').toLowerCase();

  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};
