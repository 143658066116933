import React from 'react';
import PropTypes from 'prop-types';

import Close from '../../assets/images/CloseSmall.svg';
import FlashAlert from '../../assets/images/FlashAlert.svg';
import FlashSuccess from '../../assets/images/FlashSuccess.svg';
import FlashError from '../../assets/images/FlashError.svg';
import './main.css';

const Flash = ({
  onRemove,
  removable,
  showIcon,
  text,
  textBody,
  type,
  redirect,
}) => {
  let className = 'flash';
  let FlashIcon = null;

  switch (type) {
    case 'success': {
      className += ' -success fs-exclude';
      FlashIcon = FlashSuccess;
      break;
    }
    case 'error': {
      className += ' -error';
      FlashIcon = FlashError;
      break;
    }
    case 'alert': {
      className += ' -alert';
      FlashIcon = FlashAlert;
      break;
    }
    default: {
      className += ' -success fs-exclude';
      FlashIcon = FlashSuccess;
    }
  }

  return (
    <div className="flash-wrapper">
      <div className={className}>
        <p className={textBody ? 'flash-text' : ''}>
          {showIcon && <span className="flash-icon"><FlashIcon alt="Flash icon" /></span>}
          {text}
          {redirect.text && (
            <a href={redirect.url} onClick={redirect.onClick}>
              {redirect.text}
            </a>
          )}
          {redirect.text && '.'}
        </p>
        {textBody && <p className="flash-textbody">{textBody}</p>}

        {removable && (
          <div
            className="flash-close"
            tabIndex="0"
            role="button"
            onClick={() => onRemove()}
          >
            <Close alt="Close" />
          </div>
        )}
      </div>
    </div>
  );
};

Flash.propTypes = {
  onRemove: PropTypes.func,
  redirect: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  removable: PropTypes.bool,
  showIcon: PropTypes.bool,
  text: PropTypes.string.isRequired,
  textBody: PropTypes.string,
  type: PropTypes.string.isRequired,
};

Flash.defaultProps = {
  onRemove: () => {},
  redirect: {},
  removable: true,
  showIcon: true,
  textBody: '',
};

export default Flash;
